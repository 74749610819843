<template>
<div id="app" class="site grid-container container hfeed" style="height: auto !important">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
            </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="content" class="site-content" style="height: auto !important">
    <article id="article" class="center" style="height: auto !important">
      <section id="downloadpage" style="height: auto !important">
        <!-- <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" /> -->
            <Breadcrumb :breadcrumbs="breadcrumbs" />

        <div class="download-card">
          <div class="download-header">
            <div class="site-logo">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
            </div>
            <h1>GBWhatsApp Download v18.30 Mod APK(Safe & Free)</h1>
          </div>
          <div id="download" class="wp-block-buttons aligncenter" style="width: 100%; display: block; text-align: center" @click="download()">
            <div class="wp-block-button full-button">
              <div class="godxthemes-btn wp-block-button__linkvk ripple wp-block-button__link">
                <i class="material-icons">
                  <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff">
                    <path d="m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z" />
                  </svg>
                </i>
                Download GBWhatsApp (78MB)
              </div>
            </div>
          </div>
        </div>

        <div class="download-card">
          <h2>App Info</h2>
          <figure>
            <table>
              <tr>
                <td>Name</td>
                <td>GBWhatsApp</td>
              </tr>
              <tr>
                <td>Package Name</td>
                <td>com.gbwhatsapp</td>
              </tr>
              <tr>
                <td>Publisher</td>
                <td>Alexmods</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>18.30</td>
              </tr>
              <tr>
                <td>Requires</td>
                <td>Android 5.0+</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>FREE</td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td>1 Day Ago</td>
              </tr>
            </table>
          </figure>
          <center>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button">
                <div @click="download()" class="dlbtn wp-block-button__linkvk clickable wp-block-button__link">
                  Download GB WhatsApp Pro
                </div>
              </div>
            </div>
          </center>
        </div>

        <div class="download-card">
          <h2>
            How to Download & Install GBWhatsApp?
          </h2>
          <ol>
            <li><strong>Download the APK File</strong> – Click the download button above to get the latest version.</li>
            <li><strong>Enable Unknown Sources</strong> – Go to <strong>Settings > Security > Unknown Sources</strong> and enable it.</li>
            <li><strong>Install GBWhatsApp</strong> – Open the downloaded file and tap <strong>Install</strong>.</li>
            <li><strong>Launch the App</strong> – Open GBWhatsApp and agree to the terms.</li>
            <li><strong>Verify Your Number</strong> – Enter your phone number and complete the OTP verification.</li>
          </ol>
        </div>

        <div class="download-card">
          <h2>
            Why Download GBWhatsApp from Us?
          </h2>
          <ul>
            <li><strong>100% Safe & Verified APK</strong> – No malware or fake links: <a href="https://gbwhatsapks.net/disclaimer/" class="jump-url" >Disclaimer</a></li>
            <li><strong>Fast & Direct Download</strong> – No unnecessary redirects</li>
            <li><strong>Regular Updates</strong> – Stay up to date with the latest features</li>
            <li><strong>Step-by-Step Installation Guide</strong> – Easy-to-follow instructions</li>
          </ul>
        </div>

        <div class="download-card">
          <h2>
            FAQs – GBWhatsApp Download & Installation
          </h2>
          <FAQ :faqs="faqs" />
        </div>

        <div class="download-card colored">
          <span>👉 Download GBWhatsApp APK Now! 🚀</span>
        </div>

        <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
          <footer class="site-info">
            <div class="inside-site-info grid-container">
              <div class="footer-bar">
                <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
                  |
                  <a href="https://gbwhatsapks.net/about-us/">About Us</a>
              </div>
              <div class="copyright-bar">
                2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
              </div>
            </div>
          </footer>
        </div>

      </section>
    </article>
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import FAQ from '@/components/FAQ.vue';
import Breadcrumb from '@/components/BreadCrumb.vue';


export default {
  name: 'Download',
  components: {
    FAQ,
    Breadcrumb,
  },
  data() {
    return {
      pageName: 'download',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
      faqs: [{
          "question": "Is GBWhatsApp safe to download?",
          "answer": "Yes, if downloaded from a trusted source. We ensure virus-free APKs."
        },
        {
          "question": "Can I use GBWhatsApp with the official WhatsApp?",
          "answer": "Yes, you can run both apps on the same device with different phone numbers."
        },
        {
          "question": "Is GBWhatsApp available for iPhone?",
          "answer": "No, GBWhatsApp is only available for Android devices."
        },
        {
          "question": "How do I avoid getting banned while using GBWhatsApp?",
          "answer": "Use the latest anti-ban version and avoid spam-like activities."
        }
      ]
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom(reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>

<style lang="scss" scoped>
#downloadpage {
	color: #222;
	padding: 30px 20px;
	margin: 0 180px;
	svg {
		max-width: 22px;
		max-height: 22px;
		vertical-align: middle;
		margin-bottom: 3px;
		fill: #fff;
		margin-right: 5px;
	}
}
.btns {
	a {
		padding: 5px 20px;
		background: #025c52;
		color: #fff;
		margin-bottom: 10px;
		display: block;
		border-radius: 99px;
		text-align: center;
		font-weight: 500;
	}
}
// a:hover,
// 			a:focus,
// 			a:active {
// 	color: #cacaca !important;
// }
#article {
	margin: auto;
}
// .godxthemes-btn {
// 	background-color: #1a73e8 !important;
// }
.material-icons {
	line-height: 1;
	font-size: 18px;
}
.wp-block-button__linkvk {
	color: #fff;
	background-color: #32373c;
	border: none;
	border-radius: 28px;
	box-shadow: none;
	cursor: pointer;
	display: inline-block;
	font-size: 18px;
	padding: 6px 18px;
	text-align: center;
	text-decoration: none;
	overflow-wrap: break-word;
	width: 100%;
}
// .dlbtn {
// 	background-color: #0481ff !important;
// }
.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
}
.icon {
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
}
.wp-block-button {
	justify-content: center;
	display: flex;
	font-weight: 500;
}
.full-button {
	width: 100%;
}

.download-header {
	display: flex;
	gap: 22px;
	align-items: center;
	margin-bottom: 10px;
	.site-logo {
		flex: 1;
	}
	h1 {
		flex: 6.2;
		font-size: 24px;
		line-height: 32px;
		font-weight: 600;
	}
}

.download-card {
	margin: 20px 0;
	padding: 26px 35px 5px;
	border-radius: 10px;
	box-shadow: 0px 0px 12px 2px rgba($color: #000000, $alpha: 0.1);
	table {
		tr:first-child {
			background-color: #2c3338;
			background-color: #4CB8BA;
			color: #fff;
			font-weight: 600;
		}
		tr {
			td {
				padding: 12px 15px;
				font-size: 16px;
			}
		}
	}
	.faq {
		padding-bottom: 25px;
	}
	&.colored {
		padding: 25px 30px;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-weight: 600;
		font-size: 22px;
		background: #46c4c6;
	}
}

@media (max-width: 768px) {
	#downloadpage {
		margin: 0;
		padding: 10px 20px;
		.download-card {
			padding: 24px 26px 8px;
			&.colored {
				padding: 20px 28px;
			}
		}
	}
}
</style>